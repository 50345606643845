import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars
import { Grid, Typography, Card ,CardActionArea, CardMedia, CardContent,  CardActions, Button } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST: "";

const useStyles = makeStyles((theme) =>
  createStyles({    
    card: {
      borderRadius: 12,
      height: '100%',
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",      
      // boxShadow: theme.palette.ambilight.boxshadow
    },
    cardcontent: {
      // minHeight: theme.spacing(17)
    },
    secondary: {
        maxHeight: theme.spacing(9),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
    },  
    cardActions: {
        minHeight: theme.spacing(6),      
    },
    media: {
      paddingTop: "56.25%", // 16:9    
      // backgroundSize: 'contain'
      backgroundSize: 'cover'
    }    

  })
);

const SmallSlide = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { slide } = props;
    const history = useHistory();
    
    const handleClickByCard = (e) => {
        e.stopPropagation();
        if(slide.Url !== ""){
            history.push(slide.Url);
        }
    }
    return (
      
            <Card className={classes.card}>
              <CardActionArea onClick={handleClickByCard}>
                <CardMedia
                  className={classes.media}
                  image={`${host}${slide.FullPath}`}
                  title={slide.Title}
                />
                <CardContent className={classes.cardcontent}>
                  <Typography gutterBottom variant="subtitle2" component="div" display='block' align='center'>
                    {slide.LocalValues[0].Caption === "" ? slide.Caption : slide.LocalValues[0].Caption}
                  </Typography>
                  <Typography className={classes.secondary} variant="caption" color="textSecondary" display='block' align='center'>
                    {slide.LocalValues[0].Text === "" ? slide.Text: slide.LocalValues[0].Text} 
                  </Typography>
                </CardContent>
              </CardActionArea>
              
              {slide.ButtonText !== '' && 
              <CardActions className={classes.cardActions}  >
                <Grid container direction="row" spacing={2} justifyContent="center"  alignItems="center">
                  <Grid item xs={12} sm={8} >
                    {slide.ExternalLink ? 
                    <a href={slide.Url} target="_blank" rel='noreferrer' style={{width: '100%'}}>
                        <Button size='small' fullWidth >
                            {slide.LocalValues[0].ButtonText === "" ? slide.ButtonText: slide.LocalValues[0].ButtonText}
                        </Button>
                    </a> :
                    <Button size='small' to={slide.Url} fullWidth component={Link} disabled={slide.Url === ""} >
                        {slide.LocalValues[0].ButtonText === "" ? slide.ButtonText: slide.LocalValues[0].ButtonText}
                    </Button> 
                    }
                    
                  </Grid>
                </Grid>                
              </CardActions>
            }
          </Card>        
    )
}

SmallSlide.propTypes = {
  slide:                         
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Caption: PropTypes.string.isRequired,
      Url: PropTypes.string.isRequired,
      ExternalLink: PropTypes.bool.isRequired,
      Text: PropTypes.string.isRequired,
      Path: PropTypes.string.isRequired,
      // Img: PropTypes.string.isRequired,
      FullPath: PropTypes.string.isRequired,
      IsAuthenticated: PropTypes.bool.isRequired,
      Roles: PropTypes.arrayOf(PropTypes.string),
      Hide: PropTypes.bool.isRequired,
      ShowText: PropTypes.bool.isRequired,
      ShowFrom: PropTypes.string.isRequired,
      ShowTo: PropTypes.string.isRequired,  
      ButtonText: PropTypes.string,
      LocalValues: PropTypes.arrayOf(
        PropTypes.shape({
            Id: PropTypes.number.isRequired,
            ButtonText: PropTypes.string,
            Caption: PropTypes.string.isRequired,
            Text: PropTypes.string.isRequired,
            TwoLetterISOLanguageName: PropTypes.string.isRequired,            
        })
        ).isRequired,   
    }).isRequired,
};

export default SmallSlide;